import { ButtonWrap, ModalWrap, SpinWrap } from "@/components/commons"
import { Card, Empty, Tag, Typography } from "antd"
import { Helmet } from "react-helmet-async"
import "./style.scss"
import { useQuery } from "react-query"
import { ENDPOINT } from "@/services/endpoint"
import { EventTicketService } from "@/services"
import { eventTicketStatus, eventTicketStatusColors } from "@/utils/constants"
import moment from "moment"
import { useState } from "react"
import { IModalState } from "@/types/common"
import { MODAL } from "./constants"
import { ViewDetailModal } from "./ViewDetailModal"
import { useNavigate } from "react-router-dom"
import { APP_ROUTER } from "@/routes"
import { ViewSendTicketLogModal } from "./ViewSendTicketLogModal"

interface IProps {
  title?: string
}

export const MyTicket: React.FC<IProps> = ({ title }) => {
  const navigate = useNavigate()

  const {
    data: myTickets,
    refetch: refetchMyTickets,
    isFetching: isFetchingMyTickets,
  } = useQuery(
    [ENDPOINT.EVENT_TICKET.GET_MY_TICKETS],
    () => EventTicketService.getMyTicket(),
    {
      onSuccess: (res) => {},
    },
  )

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, data?: any) => {
    switch (type) {
      case MODAL.VIEW_DETAIL:
        setModal({
          open: true,
          title: "Chi tiết",
          width: 608,
          bodyHeight: 588,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewDetailModal data={data} />,
        })
        break
      case MODAL.VIEW_SEND_TICKET_LOG:
        setModal({
          open: true,
          title: "Lịch sử nhận vé",
          width: 608,
          bodyHeight: 588,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSendTicketLogModal data={data} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  return (
    <div className="p-my-ticket">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SpinWrap spinning={isFetchingMyTickets}>
        <div className="inner">
          {myTickets?.data?.length > 0 ? (
            myTickets?.data?.map((ticket: any, index: number) => (
              <Card
                title={
                  <Typography.Paragraph type="danger" strong copyable>
                    {ticket?.ticket_code}
                  </Typography.Paragraph>
                }
                extra={
                  <ButtonWrap
                    disabled={!ticket?.name}
                    onClick={() =>
                      handleModalContent(MODAL.VIEW_DETAIL, ticket)
                    }
                  >
                    Chi tiết
                  </ButtonWrap>
                }
              >
                {ticket?.name ? (
                  <>
                    <p>Họ và tên: {ticket?.name}</p>
                    <div className="status">
                      <span>Thanh toán:</span>
                      <Tag color={eventTicketStatusColors[ticket?.status]}>
                        {eventTicketStatus[ticket?.status]}
                      </Tag>
                    </div>
                    <div className="receive-ticket">
                      <span>Nhận vé:</span>
                      {ticket?.send_ticket_logs?.length > 0
                        ? "Đã nhận"
                        : "Chưa nhận"}
                      {ticket?.send_ticket_logs?.length > 0 && (
                        <Tag
                          color="#4096ff"
                          onClick={() =>
                            handleModalContent(
                              MODAL.VIEW_SEND_TICKET_LOG,
                              ticket,
                            )
                          }
                        >
                          Xem lịch sử
                        </Tag>
                      )}
                    </div>
                    <p>
                      Thời gian đăng ký:{" "}
                      {moment(ticket?.created_at).format("HH:mm DD/MM/YY")}
                    </p>
                    <div className="status">
                      <span>Trạng thái:</span>
                      <Tag color="var(--color-yellow-2)">Chưa sử dụng</Tag>
                    </div>
                  </>
                ) : (
                  <>
                    Vé của bạn đang bị lỗi, vui lòng liên hệ với chúng tôi để
                    được hỗ trợ. Khi liên hệ vui lòng gửi cho chúng tôi mã vé
                    màu đỏ ở trên.
                  </>
                )}
              </Card>
            ))
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description="Bạn chưa có vé nào"
            >
              <ButtonWrap
                type="primary"
                onClick={() => navigate(APP_ROUTER.TICKET_REGISTER)}
              >
                Đăng ký vé
              </ButtonWrap>
            </Empty>
          )}
        </div>
      </SpinWrap>
      <ModalWrap loading={false} {...modal}>
        {modal.children}
      </ModalWrap>
    </div>
  )
}
