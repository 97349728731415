export const ENDPOINT = {
  AUTH: {
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    LOGOUT: "/auth/logout",
    GET_ME: "/auth/get-me",
  },
  FILE: {
    UPLOAD_IMAGE: "/files/upload-image",
    UPLOAD_AUDIO: "/files/upload-audio",
  },
  EVENT: {
    GET_TICKET_FAN_FORM: "/event-forms/get-ticket-fan-form",
    GET_EVENT_DETAIL: "/events/get-event-detail/:event_id",
  },
  EVENT_TICKET: {
    GET_TICKET_BY_TICKET_CODE: "/event-tickets/get-ticket-by-ticket-code",
    CREATE_TICKET_INFO: "/event-tickets/create-ticket-info",
    UPDATE_TICKET_INFO: "/event-tickets/update-ticket-info",
    CONFIRM_PAYMNET: "/event-tickets/confirm-payment",
    GET_MY_TICKETS: "/event-tickets/get-my-tickets",
  },
  EVENT_POSITION: {
    GET_ALL_BY_EVENT_ID: "/event-positions/get-all/:event_id",
  },
}
