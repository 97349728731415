import { ConfigProvider } from "antd"
import locale from "antd/es/locale/vi_VN"
import "moment/locale/vi"
import { ReactNode } from "react"
import ReactQueryProvider from "./ReactQuery"
import { StateGlobalProvider } from "./StateGlobal"
import { HelmetProvider } from "react-helmet-async"
import { AuthProvider } from "./Auth"

const AllProviders = ({ children }: { children: ReactNode }) => {
  return (
    <HelmetProvider>
      <ConfigProvider theme={{ hashed: false }} locale={locale}>
        <ReactQueryProvider>
          <AuthProvider>
            <StateGlobalProvider>{children}</StateGlobalProvider>
          </AuthProvider>
        </ReactQueryProvider>
      </ConfigProvider>
    </HelmetProvider>
  )
}

export default AllProviders
