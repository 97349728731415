import { FormItemWrap } from "@/components/commons"
import { convertThousandsFormat, getImage } from "@/utils/helpers"
import { Divider, Form, Image, Typography } from "antd"
import moment from "moment"
import "./style.scss"
import { IMAGE_TYPE } from "@/utils/constants"

interface IProps {
  data: any
}

export const ViewDetailModal: React.FC<IProps> = ({ data }) => {
  return (
    <div className="c-view-detail">
      <Form layout="vertical">
        <Divider orientation="left">Thông tin vé</Divider>
        <FormItemWrap label="Họ và tên">{data?.name}</FormItemWrap>
        <FormItemWrap label="Ngày sinh">
          {moment(data?.birthday).format("DD/MM/YYYY")}
        </FormItemWrap>
        <FormItemWrap label="Email nhận vé">{data?.email}</FormItemWrap>
        <FormItemWrap label="Số điện thoại">{data?.phone}</FormItemWrap>
        <FormItemWrap label="Mã vé">
          <Typography.Paragraph type="danger" copyable>
            {data?.ticket_code}
          </Typography.Paragraph>
        </FormItemWrap>
        <FormItemWrap label="Thời gian đăng ký">
          {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </FormItemWrap>
        <FormItemWrap label="Sự kiện">
          {data?.event?.name} (
          {moment(data?.event?.start_time).format("DD/MM/YYYY")})
        </FormItemWrap>
        <FormItemWrap label="Loại vé">
          {data?.event_position?.name} (
          {data?.event_position?.price.toLocaleString()} đ)
        </FormItemWrap>
        <Divider orientation="left">Thông tin thanh toán</Divider>
        <FormItemWrap label="Số tiền">
          {convertThousandsFormat(Number(data?.ticket_payments[0]?.amount))}{" "}
          {data?.ticket_payments[0]?.currency}
        </FormItemWrap>
        <FormItemWrap label="Nội dung chuyển khoản">
          {data?.ticket_payments[0]?.description}
        </FormItemWrap>
        <FormItemWrap label="Ngày thực hiện">
          {data?.ticket_payments[0]?.booking_date}
        </FormItemWrap>
        <FormItemWrap label="Ngày hiệu lực">
          {data?.ticket_payments[0]?.value_date}
        </FormItemWrap>
        <FormItemWrap label="Bill chuyển khoản">
          <div className="field-image">
            <Image
              src={getImage(
                data?.ticket_payments[0]?.bill_path,
                IMAGE_TYPE.THUMBNAIL,
              )}
            />
          </div>
        </FormItemWrap>
      </Form>
    </div>
  )
}
