import Countdown from "react-countdown"
import "./style.scss"

export interface IProps {
  date: string | number | Date
  format: "days" | "minutes"
}

export const CountdownTimer: React.FC<IProps> = ({ date, format }) => {
  return (
    <div className="c-countdown-timer">
      <Countdown
        date={date}
        zeroPadTime={2}
        zeroPadDays={2}
        autoStart
        precision={2}
        intervalDelay={1}
        renderer={(props) => {
          const { days, hours, minutes, seconds } = props
          const daysFormat = days
          const hoursFormat = hours
          const minutesFormat = minutes < 10 ? `0${minutes}` : minutes
          const secondsFormat = seconds < 10 ? `0${seconds}` : seconds
          return (
            <div className="countdown-bar">
              {format === "days" && (
                <>
                  <div className="time">
                    <div className="time-value">{daysFormat}</div>
                    <div className="time-name">Ngày</div>
                  </div>
                  <div className="time">
                    <div className="time-value">{hoursFormat}</div>
                    <div className="time-name">Giờ</div>
                  </div>
                </>
              )}
              <div className="time">
                <div className="time-value">{minutesFormat}</div>
                <div className="time-name">Phút</div>
              </div>
              <div className="time">
                <div className="time-value">{secondsFormat}</div>
                <div className="time-name">Giây</div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
