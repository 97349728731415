import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Layout } from "./components"
import { MeetingInfo, NotFound } from "./pages"
import { useAuth } from "./providers/Auth"
import {
  APP_ROUTER,
  PrivateRouteWrap,
  PublicRouteWrap,
  privateRoutes,
  publicRoutes,
} from "./routes"
import { getTitlePage } from "./utils/helpers"

function App() {
  const { isAuthenticated } = useAuth()

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRouteWrap isAuthenticated={isAuthenticated} />}>
          <Route>
            {publicRoutes?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              )
            })}
          </Route>
        </Route>
        <Route element={<PrivateRouteWrap isAuthenticated={isAuthenticated} />}>
          <Route element={<Layout />}>
            <Route>
              {privateRoutes?.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                )
              })}
            </Route>
          </Route>
        </Route>

        <Route
          path={APP_ROUTER.MEETING_INFO}
          element={
            <MeetingInfo
              title={getTitlePage("[TP.HCM] Đồng Âm Meeting Toàn Quốc 2024")}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
