import { ButtonWrap } from "@/components/commons"
import { useTimeout } from "@/components/hooks"
import { APP_ROUTER } from "@/routes"
import {
  EventPositionService,
  EventService,
  EventTicketService,
} from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IUploadedFile } from "@/types/common"
import { EVENT_TICKET_SESSION_TYPE_ENUMS } from "@/utils/constants"
import { convertRequest, notificationModal } from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Spin, Steps } from "antd"
import moment from "moment"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { QueryClient, useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { CountdownTimer } from "./Component"
import { FormTab, PaymentTab, SelectTicketTab } from "./Tab"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export enum TICKET_TAB {
  SELECT_TICKET = 0,
  FILL_INFO = 1,
  PAYMENT = 2,
}

export const TicketRegister: React.FC<IProps> = ({ title }) => {
  const eventId = 1
  const navigate = useNavigate()
  const queryClient = new QueryClient()
  const [current, setCurrent] = useState(0)
  const [ticketCode, setTicketCode] = useState<string | null>(null)
  const [sessionRegister, setSessionRegister] = useState<string>()
  const [sessionPayment, setSessionPayment] = useState<string>()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [uploadedBills, setUploadedBills] = useState<IUploadedFile[]>([])

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const {
    reset,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = methods

  const clearTicketInfo = () => {
    queryClient.removeQueries({
      queryKey: [
        ENDPOINT.EVENT_TICKET.GET_TICKET_BY_TICKET_CODE,
        { ticket_code: ticketCode },
      ],
    })
  }

  const {
    data: formDetail,
    refetch: refetchFormDetail,
    isFetching: isFetchingFormDetail,
  } = useQuery(
    [
      ENDPOINT.EVENT.GET_TICKET_FAN_FORM,
      {
        event_id: eventId,
      },
    ],
    () =>
      EventService.getTicketFanForm(
        convertRequest({
          event_id: eventId,
        }),
      ),
  )

  const {
    data: positions,
    refetch: refetchPositions,
    isFetching: isFetchingPositions,
  } = useQuery(
    [
      ENDPOINT.EVENT_POSITION.GET_ALL_BY_EVENT_ID,
      {
        event_id: eventId,
      },
    ],
    () => EventPositionService.getAllByEventId(eventId),
    {
      onSuccess: (res) => {},
    },
  )

  const resetForm = () => {
    refetchPositions()
    refetchFormDetail()
    setCurrent(0)
    reset({})
    setSessionRegister("")
    setSessionPayment("")
    setTicketCode("")
  }

  const {
    mutate: createTicketInfo,
    isIdle: isIdleCreateTicketInfo,
    isLoading: isLoadingCreateTicketInfo,
  } = useMutation((body: any) => EventTicketService.createTicketInfo(body), {
    onSuccess: (res: any) => {
      setCurrent(current + 1)
      setTicketCode(res?.data?.ticket_code)
    },
    onError: (error: any, body) => {
      if (error?.error_code === "SOLD_OUT") {
        resetForm()
        notificationModal({
          type: "error",
          className: "c-event-popup",
          content: "Hết vé, vui lòng đăng ký lại trong ít phút nữa.",
          maskClosable: false,
        })
      } else if (error?.error_code === "FORM_NOT_ALLOWED") {
        resetForm()
        notificationModal({
          type: "error",
          className: "c-event-popup",
          content: "Không thể truy cập vào biểu mẫu",
          maskClosable: false,
        })
      }
    },
  })

  const { data: ticketInfo, isFetching: isFetchingTicketInfo } = useQuery(
    [
      ENDPOINT.EVENT_TICKET.GET_TICKET_BY_TICKET_CODE,
      {
        ticket_code: ticketCode,
      },
    ],
    () => EventTicketService.getTicketByTicketCode({ ticket_code: ticketCode }),
    {
      enabled: !!ticketCode,
      onSuccess: (res) => {
        const regSession = res?.data?.ticket_sessions?.find(
          (session: any) =>
            session.type === EVENT_TICKET_SESSION_TYPE_ENUMS.REGISTER,
        ).end_time
        setSessionRegister(regSession)
      },
    },
  )

  const {
    mutate: updateTicketInfo,
    isIdle: isIdleUpdateTicketInfo,
    isLoading: isLoadingUpdateTicketInfo,
  } = useMutation((body: any) => EventTicketService.updateTicketInfo(body), {
    onSuccess: (res: any) => {
      const paymentSession = res?.data?.ticket_sessions?.find(
        (session: any) =>
          session.type === EVENT_TICKET_SESSION_TYPE_ENUMS.PAYMENT,
      ).end_time
      setSessionPayment(paymentSession)
      setCurrent(current + 1)
    },
    onError: (error: any, body) => {
      if (error?.error_code === "TICKET_TIME_OUT") {
        resetForm()
        notificationModal({
          type: "error",
          className: "c-event-popup",
          content: "Hết thời gian đăng ký vé!",
          maskClosable: false,
        })
      } else if (error?.error_code === "FORM_NOT_ALLOWED") {
        resetForm()
        notificationModal({
          type: "error",
          className: "c-event-popup",
          content: "Không thể truy cập vào biểu mẫu!",
          maskClosable: false,
        })
      }
    },
  })

  const { mutate: confirmPayment, isLoading: isLoadingConfirmPayment } =
    useMutation((body: any) => EventTicketService.confirmPayment(body), {
      onSuccess: (res: any) => {
        navigate(APP_ROUTER.MY_TICKET)
        notificationModal({
          type: "info",
          className: "c-event-popup",
          content:
            "Đăng ký vé thành công, chúng tôi đang kiểm tra thanh toán của bạn!",
          maskClosable: false,
        })
      },
      onError: (error: any, body) => {
        if (error?.error_code === "PAYMENT_TIME_OUT") {
          resetForm()
          notificationModal({
            type: "confirm",
            className: "c-event-popup",
            content: "Hết thời gian thanh toán",
            maskClosable: false,
          })
        } else if (error?.error_code === "FORM_NOT_ALLOWED") {
          resetForm()
          notificationModal({
            type: "confirm",
            className: "c-event-popup",
            content: "Không thể truy cập vào biểu mẫu",
            maskClosable: false,
          })
        }
      },
    })

  const steps = [
    {
      title: "Chọn loại vé",
      content: <SelectTicketTab positions={positions?.data} />,
    },
    {
      title: "Nhập thông tin",
      content: (
        <FormTab sessionRegister={sessionRegister} resetForm={resetForm} />
      ),
    },
    {
      title: "Thanh toán",
      content: (
        <PaymentTab
          ticketInfo={ticketInfo}
          uploadedBills={uploadedBills}
          setUploadedBills={setUploadedBills}
          sessionPayment={sessionPayment}
          resetForm={resetForm}
        />
      ),
    },
  ]

  const handleNext = async () => {
    if (current === 0) {
      if (!formMonitor.position_id) {
        notificationModal({
          type: "warning",
          className: "c-event-popup",
          content: "Vui lòng chọn loại vé",
          maskClosable: false,
        })
        return
      }
      createTicketInfo({
        event_id: eventId,
        position_id: formMonitor.position_id,
      })
    } else if (current === 1) {
      const isValidForm = await trigger()
      if (!isValidForm) {
        return
      } else {
        updateTicketInfo({
          event_id: eventId,
          ticket_code: ticketCode,
          name: formMonitor.name,
          birthday: formMonitor?.birthday.format("YYYY-MM-DD"),
          gender: formMonitor.gender,
          email: formMonitor.email,
          phone: formMonitor.phone,
          facebook: formMonitor.facebook,
        })
      }
    }

    setCurrent(current + 1)
  }

  const handlePrevious = () => {
    if (current === 1) {
      !isIdleCreateTicketInfo &&
        notificationModal({
          type: "confirm",
          className: "c-event-popup",
          content:
            "Nếu bạn quay trở về, bạn sẽ đăng ký vé lại từ đầu. Sẽ có trường hợp hết vé",
          okText: "Trở về",
          cancelText: "Ở lại",
          maskClosable: false,
          onOk: () => {
            setCurrent(0)
            setValue("position_id", null)
            clearTicketInfo()
            setSessionRegister("")
            refetchFormDetail()
          },
        })
      return
    } else if (current === 2) {
      !isIdleUpdateTicketInfo &&
        notificationModal({
          type: "confirm",
          className: "c-event-popup",
          content:
            "Nếu bạn quay trở về, bạn sẽ đăng ký vé lại từ đầu. Sẽ có trường hợp hết vé",
          okText: "Trở về",
          cancelText: "Ở lại",
          maskClosable: false,
          onOk: () => {
            setCurrent(0)
            reset({})
            clearTicketInfo()
            setSessionRegister("")
            setSessionPayment("")
            refetchFormDetail()
          },
        })
      return
    }
    setCurrent(current - 1)
  }

  const handleSubmit = () => {
    if (!uploadedBills[0]) {
      return notificationModal({
        type: "error",
        className: "c-event-popup",
        content: "Vui lòng Tải lên bill chuyển tiền",
        maskClosable: false,
      })
    }

    confirmPayment({
      ticket_code: ticketCode,
      bill_path: uploadedBills[0]?.url,
      event_id: eventId,
    })
  }

  const getLoadingNext = (currentTab: number): boolean => {
    switch (currentTab) {
      case TICKET_TAB.FILL_INFO:
        return isLoadingCreateTicketInfo
      default:
        return false
    }
  }

  const items = steps.map((item) => ({ key: item.title, title: item.title }))

  const targetTime = formDetail?.open_time
  const futureDate = moment(targetTime)
  const now = moment()
  const diff = futureDate.diff(now)

  useTimeout(() => setOpen(true), diff === -1 || diff === 0 ? 100000000 : diff)

  const formMonitor = useWatch({
    control,
  })

  return (
    <FormProvider {...methods}>
      <div className="p-ticket-register">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="title">{formDetail?.title}</div>
        {ticketInfo && sessionRegister && !sessionPayment && (
          <div className="remaining-time no-select">
            <span>Thời gian đăng ký còn lại:</span>
            <CountdownTimer date={sessionRegister} format="minutes" />
          </div>
        )}
        {ticketInfo && sessionPayment && (
          <div className="remaining-time no-select">
            <span>Thời gian thanh toán còn lại:</span>
            <CountdownTimer date={sessionPayment} format="minutes" />
          </div>
        )}
        <div className="ticket-form no-select">
          <Steps current={current} items={items} />
          <Spin spinning={getLoadingNext(current)}>
            <div className="body">{steps[current].content}</div>
          </Spin>
          <div className="footer">
            {current > 0 ? (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => handlePrevious()}
              >
                Trở về
              </Button>
            ) : (
              <div></div>
            )}
            {current < steps.length - 1 && (
              <ButtonWrap type="primary" onClick={() => handleNext()}>
                Tiếp tục
              </ButtonWrap>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={handleSubmit}>
                Xác nhận
              </Button>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  )
}
