import { IMAGE_TYPE, SELECT_TYPE } from "@/utils/constants"
import { getImage } from "@/utils/helpers"
import { Select } from "antd"
import { SelectProps } from "antd/lib"
import { ControllerRenderProps } from "react-hook-form"
import "./style.scss"

interface IInputWrapProps extends SelectProps {
  field?: ControllerRenderProps<any, any>
  type?: SELECT_TYPE
}

export const SelectWrap: React.FC<IInputWrapProps> = ({
  className,
  options,
  field,
  type,
  ...props
}) => {
  const getLabel = (item: any, type?: SELECT_TYPE) => {
    switch (type) {
      case SELECT_TYPE.SLIDER_TYPE:
        return (
          <div className="label-selector s-slider-type">
            <span>{item.label}</span>
            <div
              className="bg-color"
              style={{ background: item.original.bg_color }}
            ></div>
          </div>
        )
      case SELECT_TYPE.SONG_GROUP:
        return (
          <div className="label-selector s-song-group">
            <img
              src={getImage(item.original.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
              alt={item.label}
            />
            <span>{item.label}</span>
          </div>
        )
      case SELECT_TYPE.ARTIST:
        return (
          <div className="label-selector s-artist">
            <img
              src={getImage(item.original.avatar_path, IMAGE_TYPE.THUMBNAIL)}
              alt={item.label}
            />
            <span>{item.label}</span>
          </div>
        )
      default:
        return <span>{item.label}</span>
    }
  }
  return (
    <Select className={className} {...field} {...props}>
      {(options ?? []).map((item) => {
        return (
          <Select.Option key={item?.value} value={item?.value}>
            {getLabel(item, type)}
          </Select.Option>
        )
      })}
    </Select>
  )
}
