import {
  ButtonWrap,
  FormItemWrap,
  InputPasswordWrap,
  InputWrap,
} from "@/components/commons"
import { useAuth } from "@/providers/Auth"
import { APP_ROUTER } from "@/routes"
import { AuthService } from "@/services"
import { ILoginRequest, IRegisterRequest } from "@/types/request"
import { NOTIFICATION_STATUS } from "@/utils/constants"
import { notificationMessage, t } from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Form } from "antd"
import { Helmet } from "react-helmet-async"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface ILoginProps {
  title?: string
}

export const Register: React.FC<ILoginProps> = ({ title }) => {
  const navigate = useNavigate()
  const { logInSuccess, setCurrentUser } = useAuth()

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  const { mutate: registerLogin, isLoading } = useMutation(
    (body: IRegisterRequest) => AuthService.register(body),
    {
      onSuccess: (response) => {
        notificationMessage({
          message: "Đăng ký thành công",
          type: NOTIFICATION_STATUS.SUCCESS,
        })
        navigate(APP_ROUTER.AUTH.LOGIN)
      },
      onError: (error: any) => {
        notificationMessage({
          message: error.message,
          type: NOTIFICATION_STATUS.ERROR,
        })
      },
    },
  )

  const handleRegister = (values: any) => {
    registerLogin(values)
  }

  return (
    <div className="p-login">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="login-form">
        <div className="header">
          <span className="title">Đăng ký</span>
          <span className="description">Trang đăng ký dành cho Đồng Âm</span>
        </div>
        <Form
          layout="vertical"
          onFinish={handleSubmit(handleRegister)}
          className="body"
        >
          <FormItemWrap
            label="Họ và tên"
            required
            validateStatus={errors?.full_name ? "error" : ""}
            help={<>{errors?.full_name?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="full_name"
              control={control}
              render={({ field }) => (
                <InputWrap
                  placeholder="Ví dụ: Nguyễn Minh Cảnh"
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Email"
            required
            validateStatus={errors?.email ? "error" : ""}
            help={<>{errors?.email?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputWrap
                  placeholder="Ví dụ: mail@example.com"
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Mật khẩu"
            required
            validateStatus={errors?.password ? "error" : ""}
            help={<>{errors?.password?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <InputPasswordWrap
                  placeholder="Nhập mật khẩu"
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Nhập lại mật khẩu"
            required
            validateStatus={errors?.password_confirmation ? "error" : ""}
            help={<>{errors?.password_confirmation?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="password_confirmation"
              control={control}
              render={({ field }) => (
                <InputPasswordWrap
                  placeholder="Nhập lại mật khẩu"
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <ButtonWrap
            className="login-btn"
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            Xác nhận
          </ButtonWrap>
          <ButtonWrap
            className="forget-btn"
            type="link"
            size="large"
            onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
          >
            Bạn đã có tài khoản? Đăng nhập
          </ButtonWrap>
        </Form>
      </div>
    </div>
  )
}
