import { useAuth } from "@/providers/Auth"
import { APP_ROUTER } from "@/routes"
import { AuthService } from "@/services"
import { IMAGE_TYPE, NOTIFICATION_STATUS, ROLE_TYPES } from "@/utils/constants"
import { getImage, notificationMessage, t } from "@/utils/helpers"
import { Dropdown, MenuProps } from "antd"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import "./style.scss"

interface IAccountDropdownProps {
  children: React.ReactNode
}

export const AccountDropdown: React.FC<IAccountDropdownProps> = ({
  children,
}) => {
  const navigate = useNavigate()
  const { isAuthenticated, currentUser, logOutSuccess } = useAuth()

  const { mutate: mutateLogout } = useMutation(() => AuthService.logout(), {
    onSuccess: () => {
      logOutSuccess()
      navigate(APP_ROUTER.AUTH.LOGIN)
    },
    onError: (error: any) => {
      notificationMessage({
        message: error.message,
        type: NOTIFICATION_STATUS.ERROR,
      })
    },
  })

  const items: MenuProps["items"] = [
    {
      key: "1",
      type: "group",
      label: t("LAYOUT.HEADER.ACCOUNT_DROPDOWN.GROUP_LOGIN"),
      children: [
        {
          key: "1-1",
          label: (
            <div className="user-info">
              <div className="avatar">
                <img
                  src={getImage(currentUser?.avatar_path!, IMAGE_TYPE.AVATAR)}
                  alt=""
                />
              </div>
              <div className="right">
                <div className="name">
                  {isAuthenticated && currentUser?.full_name}
                </div>
                <div className="position">
                  {Number(currentUser?.role_id) === ROLE_TYPES.ADMIN
                    ? "Quản trị viên"
                    : "Đồng Âm"}
                </div>
                <div className="username">
                  @{isAuthenticated && currentUser?.username}
                </div>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      label: t("LAYOUT.HEADER.ACCOUNT_DROPDOWN.GROUP_MORE_OPTIONS"),
      type: "group",
      children: [
        // {
        //   key: "2-1",
        //   label: (
        //     <Link to="/settings">
        //       <span className="label">
        //         {t("LAYOUT.HEADER.ACCOUNT_DROPDOWN.LABEL_SETTING")}
        //       </span>
        //     </Link>
        //   ),
        // },
        {
          key: "2-2",
          label: (
            <div onClick={() => mutateLogout()}>
              <span className="label">
                {t("LAYOUT.HEADER.ACCOUNT_DROPDOWN.LABEL_LOGOUT")}
              </span>
            </div>
          ),
        },
      ],
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={["click"]}
      overlayClassName="c-account-dropdown"
    >
      {children}
    </Dropdown>
  )
}
