import { ButtonWrap, SpinWrap } from "@/components/commons"
import { EventService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { yupResolver } from "@hookform/resolvers/yup"
import { Icon } from "@iconify/react"
import { Image, Tabs } from "antd"
import parse from "html-react-parser"
import { capitalize } from "lodash"
import moment from "moment"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"
import { APP_ROUTER } from "@/routes"

interface ILoginProps {
  title?: string
}

export const MeetingInfo: React.FC<ILoginProps> = ({ title }) => {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const eventId = 1

  const { data: eventDetail, isFetching } = useQuery(
    [
      ENDPOINT.EVENT.GET_EVENT_DETAIL,
      {
        event_id: eventId,
      },
    ],
    () => EventService.getEventDetail(eventId),
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  const guidelineImages = [
    {
      id: 1,
      src: "https://i.ibb.co/ZWpHKQV/1.jpg",
    },
    {
      id: 2,
      src: "https://i.ibb.co/4pPZ13w/2.jpg",
    },
    {
      id: 3,
      src: "https://i.ibb.co/6JLrrvM/3.jpg",
    },
    {
      id: 4,
      src: "https://i.ibb.co/yBchzkw/4.jpg",
    },
    {
      id: 5,
      src: "https://i.ibb.co/bHBBb6Z/5.jpg",
    },
    {
      id: 6,
      src: "https://i.ibb.co/R02gX4Q/6.jpg",
    },
    {
      id: 7,
      src: "https://i.ibb.co/GRzPVX2/7.jpg",
    },
    {
      id: 8,
      src: "https://i.ibb.co/VwZdZ7Z/8.jpg",
    },
    {
      id: 9,
      src: "https://i.ibb.co/svT2cPh/9.jpg",
    },
    {
      id: 10,
      src: "https://i.ibb.co/9WcqnxZ/10.jpg",
    },
  ]

  const spaces = [
    {
      id: 1,
      src: "https://i.ibb.co/LJV8Mqc/meeting-1.webp",
    },
    {
      id: 2,
      src: "https://i.ibb.co/xHg8WQy/meeting-2.webp",
    },
    {
      id: 3,
      src: "https://i.ibb.co/72mCk5C/meeting-3.webp",
    },
    {
      id: 4,
      src: "https://i.ibb.co/n385n7K/meeting-4.webp",
    },
    {
      id: 5,
      src: "https://i.ibb.co/MgXyN8Z/meeting-5.webp",
    },
    {
      id: 6,
      src: "https://i.ibb.co/58sgJyN/meeting-6.webp",
    },
    {
      id: 7,
      src: "https://i.ibb.co/tLc4jfL/meeting-7.webp",
    },
    {
      id: 8,
      src: "https://i.ibb.co/5rtCjJf/meeting-8.webp",
    },
  ]

  const eventInfo = eventDetail?.data

  const onRedirectRegister = () => {
    navigate(APP_ROUTER.TICKET_REGISTER)
  }

  const guideline = (
    <div className="guideline">
      <Image.PreviewGroup>
        {guidelineImages?.map((image, index) => (
          <Image key={index} width={200} src={image?.src} />
        ))}
      </Image.PreviewGroup>
    </div>
  )

  const spacesRender = (
    <div className="space-img">
      <Image.PreviewGroup>
        {spaces?.map((image, index) => (
          <Image key={index} src={image?.src} />
        ))}
      </Image.PreviewGroup>
    </div>
  )

  const benefitsRender = (
    <Image.PreviewGroup>
      <Image src="https://i.ibb.co/3fBwsK6/meeting-quyenloi.jpg" />
    </Image.PreviewGroup>
  )

  const items = [
    {
      label: "Thông tin",
      children: <>{parse(eventInfo?.description || "")}</>,
      key: "thong-tin",
    },
    { label: "Hướng dẫn đăng ký vé", children: guideline, key: "huong-dan" },
    { label: "Không gian sự kiện", children: spacesRender, key: "khong-gian" },
    { label: "Quyền lợi tham gia", children: benefitsRender, key: "quyen-loi" },
  ]

  return (
    <div className="p-meeting-info">
      <ButtonWrap
        type="primary"
        className="register-btn-mobile"
        danger
        onClick={onRedirectRegister}
      >
        ĐĂNG KÝ VÉ (550.000đ)
      </ButtonWrap>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SpinWrap spinning={isFetching}>
        <div className="container">
          <Image src={eventInfo?.thumbnail_path} />
          <div className="body">
            <div className="header">
              <div className="left">
                <div className="event-title">
                  {eventDetail ? eventInfo?.name : "????"}
                </div>
              </div>
              <ButtonWrap
                type="primary"
                className="register-btn"
                danger
                onClick={onRedirectRegister}
              >
                ĐĂNG KÝ VÉ (550.000đ)
              </ButtonWrap>
            </div>
            {eventDetail && (
              <>
                <div className="time">
                  <div className="icon">
                    <Icon color="#ff4d4f" width={20} icon="mdi:clock" />
                  </div>
                  {capitalize(
                    moment(eventInfo?.start_time)
                      .format("dddd, DD/MM/YYYY")
                      .toUpperCase(),
                  )}{" "}
                  ({moment(eventInfo?.start_time).format("HH:mm")} -{" "}
                  {moment(eventInfo?.end_time).format("HH:mm")})
                </div>
                <div className="location">
                  <div className="icon">
                    <Icon
                      color="#ff4d4f"
                      width={20}
                      icon="mingcute:location-fill"
                    />
                  </div>
                  {`${eventInfo?.place_name} - ${eventInfo?.house_number} ${eventInfo?.street_name}, ${eventInfo?.ward_name}, ${eventInfo?.province_name}`}
                </div>
              </>
            )}
            <Tabs
              defaultActiveKey={searchParams.get("tab")!}
              items={items}
              className="tabs"
              onChange={(key) => {
                setSearchParams({
                  tab: key,
                })
              }}
            />
          </div>
        </div>
      </SpinWrap>
    </div>
  )
}
