/* eslint-disable no-useless-escape */

import { Options } from "@/types/common"

export const SERVER_URL = process.env.REACT_APP_BACKEND_URL

export enum IMAGE_TYPE {
  THUMBNAIL = "thumbnail",
  AVATAR = "avatar",
  LOGO = "logo",
}

export enum SELECT_TYPE {
  SLIDER_TYPE = "SliderType",
  SONG_GROUP = "song_group",
  NEWS_AGENCY = "news_agency",
  NEWS_TOPIC = "news_topic",
  SONG_GROUP_TYPE = "song_group_type",
  ARTIST = "artist",
  EVENT_TYPE = "event_type",
  URL_TYPE = "url_type",
  ACCESS_MODE = "access_mode",
  AGE_LIMIT = "age_limit",
  ENCRYPTION = "encryption",
  FROM_EMAIL = "from_email",
  USER = "user",
  PROFILE = "profile",
}

export enum NOTIFICATION_STATUS {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export enum PARAM_FOR {
  FORM = "form",
  PARAM = "form",
}

export const avatarSample =
  "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"

export const REGEX = {
  NAME: /^([a-z]+)((\s{1}[a-z]+){1,})$/,
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PASSWORD:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@¥$!%*#?&(){}\[\]<>:;\-_,."'+|\\\/^])[A-Za-z\d@¥$!%*#?&(){}\[\]<>:;\-_,."'+|\\\/^]{8,}$/,
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  FACEBOOK_URL:
    /^(?:https?:\/\/)?(?:www\.|m\.|mbasic\.|web\.)?facebook\.com\/[a-zA-Z0-9_\-\.]+(?:\?(?:\w+=[\w\d]+&?)*\w+=[\w\d]+)?$/i,
}

export const genderOptions: Options = {
  1: "Nam",
  2: "Nữ",
  3: "Chưa xác định",
}

export const displayStatusOptions: Options = {
  0: "Ẩn",
  1: "Hiện",
}

export const colorStyleOptions: Options = {
  1: "Simple",
  2: "Gradient",
}

export const gradientStyleOptions: Options = {
  1: "Trái sang phải",
  2: "Phải sang trái",
}

export enum UPLOAD_LIST_TYPE {
  TEXT = "text",
  PICTURE = "picture",
  PICTURE_CARD = "picture-card",
  PICTURE_CIRCLE = "picture-circle",
}

export enum FILE_TYPE {
  AUDIO = "audio",
  IMAGE = "image",
  VIDEO = "video",
}

export const songGroupTypes: Options = {
  album: "Album",
  mixtape: "Mixtape",
  lp: "LP",
  ep: "EP",
  single: "Single",
}

export const songReleaseStatus: Options = {
  0: "Chưa phát hành",
  1: "Đã phát hành",
}

export const songReleaseColors: Options = {
  0: "var(--color-red-1)",
  1: "var(--color-green-1)",
}

export const songWarningOptions: Options = {
  0: "Không",
  1: "Có",
}

export enum ATTACHED_TICKET_TYPE_ENUMS {
  QR_TICKET = 1,
  PDF_TICKET = 2,
  UPLOAD_TICKET = 3,
}

export const attachedTicketTypes: Options = {
  1: "Mã QR (Auto generate)",
  2: "Vé PDF (Auto generate)",
  3: "Vé (Upload manually)",
}

export const eventPaymentOptions: Options = {
  0: "Không",
  1: "Có",
}

export const eventDrinkOptions: Options = {
  0: "Không",
  1: "Có",
}

export const eventFormUrlTypes: Options = {
  1: "Liên kết hệ thống",
  2: "Liên kết tự chọn",
}

export enum EVENT_FORM_URL_TYPE_ENUMS {
  DEFAULT = 1,
  OPTIONAL = 2,
}

export enum EVENT_FORM_TYPE_ENUMS {
  TICKET_FAN = 1,
  TICKET_TEAM = 2,
  JOIN_SUPPORT = 3,
  JOIN_MEDIA = 4,
  JOIN_TALENT = 5,
  ATTENDANCE = 6,
}

export enum EVENT_FORM_STATUS_ENUMS {
  ALWAYS_CLOSE = 1,
  ALWAYS_OPEN = 2,
  WILL_OPEN_AT_A_TIME = 3,
  WILL_CLOSE_AT_A_TIME = 4,
  DEPENDS_ON_TIME_PERIOD = 5,
}

export enum EVENT_TICKET_SESSION_TYPE_ENUMS {
  REGISTER = 1,
  PAYMENT = 2,
}

export enum ERROR_CODES {
  INVALID_ACCOUNT = "INVALID_ACCOUNT",
}

export enum ROLE_TYPES {
  ADMIN = 1,
  USER = 2,
}

export const eventTicketStatus: Options = {
  3: "Đang xác minh",
  4: "Thành công",
  5: "Thất bại",
  6: "Bị huỷ",
}

export const eventTicketStatusColors: Options = {
  3: "var(--color-yellow-2)",
  4: "var(--color-green-1)",
  5: "var(--color-red-1)",
  6: "var(--color-red-1)",
}

export enum EVENT_TICKET_STATUS {
  INITIALIZED = 1,
  PENDING_PAYMENT = 2,
  VERIFICATION_REQUEST = 3,
  REGISTRATION_SUCCESSFUL = 4,
  VERIFICATION_FAILED = 5,
  CANCELED = 6,
}
