import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EventPosition {
  getAllByEventId = (eventId: number): any => {
    return http.get(
      ENDPOINT.EVENT_POSITION.GET_ALL_BY_EVENT_ID.replace(
        ":event_id",
        String(eventId),
      ),
    )
  }
}

export const EventPositionService = new EventPosition()
