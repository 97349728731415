import { FormItemWrap } from "@/components/commons"
import { Divider, Form, Tag } from "antd"
import moment from "moment"
import "./style.scss"

interface IProps {
  data: any
}

export const status: any = {
  1: "Thành công",
  2: "Thất bại",
}

export const color: any = {
  1: "var(--color-green-1)",
  2: "var(--color-red-1)",
}

export const ViewSendTicketLogModal: React.FC<IProps> = ({ data }) => {
  return (
    <div className="c-view-send-ticket-log">
      <Form layout="vertical">
        {data?.send_ticket_logs?.map((log: any, index: number) => (
          <div key={index}>
            <Divider orientation="left">
              {moment(log?.created_at).format("HH:mm DD/MM/YYYY")}
            </Divider>
            <FormItemWrap label="Email nhận">
              {log?.receiver_email}
            </FormItemWrap>
            <FormItemWrap label="Trạng thái">
              <Tag color={color[log?.status]}>{status[log?.status]}</Tag>
            </FormItemWrap>
          </div>
        ))}
      </Form>
    </div>
  )
}
