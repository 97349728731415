import { Radio } from "antd"
import "./style.scss"

export const TicketOptions: React.FC<any> = ({
  className,
  field,
  options,
  ...props
}) => {
  return (
    <Radio.Group className="c-ticket-options" {...field} {...props}>
      {options?.map((ticket: any) => {
        return (
          <Radio value={ticket.id} disabled={!ticket.is_enabled}>
            <div className="option">
              <span className="ticket-name">
                <strong>Loại vé:</strong> {ticket.name}
              </span>
              <span className="ticket-price">
                <strong>Giá vé:</strong> {ticket.price.toLocaleString()}đ
              </span>
              <div>
                <strong>Trạng thái:</strong>{" "}
                {ticket.is_enabled ? "Còn vé" : "Hết vé"}
              </div>
              <div>
                <strong>Mô tả:</strong>
                <div className="description">{ticket.description}</div>
              </div>
            </div>
          </Radio>
        )
      })}
    </Radio.Group>
  )
}
