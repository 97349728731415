import { RadioGroupWrap } from "@/components/commons"
import "./style.scss"
import { TicketOptions } from "../../Component"
import { Controller, useFormContext } from "react-hook-form"

export interface ITicketOption {
  id: number
  name: string
  price: number
  is_enabled: boolean
  description?: string
}
export const SelectTicketTab = ({ positions }: any) => {
  const { control } = useFormContext()

  return (
    <div className="t-select-ticket">
      <Controller
        name="position_id"
        control={control}
        render={({ field }) => (
          <TicketOptions options={positions} field={field} />
        )}
      />
    </div>
  )
}
