import { CalendarIcon, TicketIcon } from "@/components/icons"
import { APP_ROUTER } from "@/routes"

export const APP_MENU = [
  {
    name: "Đăng ký vé",
    path: APP_ROUTER.TICKET_REGISTER,
    icon: <CalendarIcon />,
  },
  {
    name: "Vé của tôi",
    path: APP_ROUTER.MY_TICKET,
    icon: <TicketIcon />,
  },
]
