import { IResponse } from "@/types/common"
import { IGetMeResponse, ILoginResponse } from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"
import { ILoginRequest, IRegisterRequest } from "@/types/request"
import { setCookie } from "@/utils/helpers/storage"

class Auth {
  login = async (body: ILoginRequest): Promise<IResponse<ILoginResponse>> => {
    const res: IResponse<ILoginResponse> = await http.post(
      ENDPOINT.AUTH.LOGIN,
      body,
    )

    const accessToken = res?.data?.access_token

    if (accessToken) {
      setCookie("accessToken", accessToken, { expires: 365 })
    }

    return res
  }

  register = async (body: IRegisterRequest) => {
    const res: IResponse<ILoginResponse> = await http.post(
      ENDPOINT.AUTH.REGISTER,
      body,
    )

    return res
  }

  getMe = async (): Promise<IResponse<IGetMeResponse>> => {
    const res: IResponse<IGetMeResponse> = await http.get(ENDPOINT.AUTH.GET_ME)
    return res
  }

  logout = () => {
    return http.post(ENDPOINT.AUTH.LOGOUT)
  }
}

export const AuthService = new Auth()
