import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Event {
  getTicketFanForm = ({ event_id }: any): any => {
    return http.get(ENDPOINT.EVENT.GET_TICKET_FAN_FORM, {
      params: {
        event_id,
      },
    })
  }

  getEventDetail = (event_id: number): any => {
    return http.get(
      ENDPOINT.EVENT.GET_EVENT_DETAIL.replace(":event_id", String(event_id)),
    )
  }
}

export const EventService = new Event()
