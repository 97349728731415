import { InputWrap, TooltipWrap } from "@/components/commons"
import { useAuth } from "@/providers/Auth"
import { IMAGE_TYPE } from "@/utils/constants"
import { getImage, t } from "@/utils/helpers"
import { MenuFoldOutlined } from "@ant-design/icons"
import { Badge } from "antd"
import { AccountDropdown } from "./AccountDropdown"
import "./style.scss"

interface IHeaderProps {
  onShowMenu: () => void
}
export const Header: React.FC<IHeaderProps> = ({ onShowMenu }) => {
  const { currentUser } = useAuth()
  return (
    <div className="c-header">
      <div className="left">
        <TooltipWrap
          title={t("LAYOUT.HEADER.TOOLTIP_SHOW_MENU")}
          placement="bottomRight"
        >
          <div className="show-menu" onClick={onShowMenu}>
            <MenuFoldOutlined />
          </div>
        </TooltipWrap>
      </div>
      <div className="right">
        <InputWrap
          placeholder={t("LAYOUT.HEADER.PLACEHOLDER_SEARCH")}
          prefix={<img src="/icons/search.svg" alt="" />}
        />
        <Badge count={0}>
          <div className="notification">
            <img src="/icons/ring.svg" alt="" />
          </div>
        </Badge>
        <AccountDropdown>
          <div className="account">
            <img
              src={getImage(currentUser?.avatar_path!, IMAGE_TYPE.AVATAR)}
              alt=""
            />
          </div>
        </AccountDropdown>
      </div>
    </div>
  )
}
