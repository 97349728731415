import { ButtonWrap, SelectWrap } from "@/components/commons"
import UploadWrap from "@/components/commons/UploadWrap"
import { useTimeout } from "@/components/hooks"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { UPLOAD_LIST_TYPE } from "@/utils/constants"
import { convertObjectToArray, notificationModal } from "@/utils/helpers"
import { Alert, Typography, UploadFile } from "antd"
import { saveAs } from "file-saver"
import moment from "moment"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import "./style.scss"

export interface IProps {
  ticketInfo: any
  uploadedBills: any
  setUploadedBills: any
  sessionPayment: any
  resetForm: any
}
export const PaymentTab: React.FC<IProps> = ({
  ticketInfo,
  uploadedBills,
  setUploadedBills,
  sessionPayment,
  resetForm,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<number>(1)

  const { mutate: uploadBill, isLoading: isLoadingUploadBill } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedBills((prevUploadBills: any) =>
          prevUploadBills.map((file: any) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedBills((prevUploadFiles: any) =>
          prevUploadFiles.map((file: any) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeThumbnail = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedBills((prevUploadBills: any) => [...prevUploadBills, tempFile])

      uploadBill({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedBills((prevUploadBills: any) =>
        prevUploadBills.filter(
          (uploadFile: any) => uploadFile.uid !== file.uid,
        ),
      )
    }
  }
  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  const downloadImage = () => {
    saveAs(
      `https://img.vietqr.io/image/tpbank-denvaufc-compact.jpg?amount=${ticketPrice}&addInfo=${paymentContent}&accountName=DENVAUFC`,
      "qr_code.jpg",
    ) // Put your image URL here.
  }

  const invalidFormAction = () => {
    resetForm()
    notificationModal({
      type: "error",
      className: "c-event-popup",
      content: "Hết thời gian thanh toán!",
      maskClosable: false,
    })
  }

  const futureDate = moment(sessionPayment)
  const now = moment()
  const diff = futureDate.diff(now)

  useTimeout(
    () => invalidFormAction(),
    diff === -1 || diff === 0 || Number.isNaN(diff) ? 100000000 : diff,
  )

  const paymentMethods = {
    1: "Thanh toán qua VIETQR",
    2: "Thanh toán Thủ công",
  }

  const ticketPrice = ticketInfo?.data?.event_position?.price
  const paymentContent = ticketInfo?.data?.ticket_code
  return (
    ticketInfo && (
      <div className="t-payment-tab">
        <SelectWrap
          options={convertObjectToArray(paymentMethods)}
          defaultValue={paymentMethod}
          style={{ width: "220px" }}
          onChange={(e) => setPaymentMethod(e)}
        />
        {paymentMethod === 1 && (
          <>
            <span className="note">
              Bước 1: Quét mã QR phía bên dưới để thanh toán:
            </span>
            <img
              className="qr-code"
              src={`https://img.vietqr.io/image/tpbank-denvaufc-compact.jpg?amount=${ticketPrice}&addInfo=${paymentContent}&accountName=DENVAUFC`}
              alt=""
            />
            <ButtonWrap className="download-qr" onClick={() => downloadImage()}>
              Tải mã QR
            </ButtonWrap>
          </>
        )}

        {paymentMethod === 2 && (
          <>
            <span className="note">
              Bước 1: Chuyển khoản thủ công với nội dung bên dưới:
            </span>
            <div className="bank-info">
              Tên ngân hàng:
              <Typography.Paragraph type="danger" strong>
                TPBank
              </Typography.Paragraph>
            </div>
            <div className="bank-info">
              Số tài khoản nhận tiền:
              <Typography.Paragraph type="danger" strong copyable>
                19181098888
              </Typography.Paragraph>
            </div>
            <div className="bank-info">
              Chủ tài khoản nhận tiền:
              <Typography.Paragraph type="danger" strong>
                NGUYEN MINH CANH
              </Typography.Paragraph>
            </div>
            <div className="bank-info">
              Số tiền:
              <Typography.Paragraph type="danger" strong copyable>
                {ticketPrice.toLocaleString()}đ
              </Typography.Paragraph>
            </div>
            <div className="bank-info">
              Nội dung chuyển khoản:
              <Typography.Paragraph type="danger" strong copyable>
                {paymentContent}
              </Typography.Paragraph>
            </div>
          </>
        )}
        <span className="note">Bước 2: Tải lên bill chuyển tiền của bạn:</span>
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
          onChangeFiles={handleChangeThumbnail}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedBills}
          maxCount={1}
          accept="image/*"
        />
        <Alert
          message={
            <>
              Sau khi hoàn tất 2 bước trên, vui lòng nhấn nút{" "}
              <strong>Xác nhận</strong> ở phía bên dưới để hoàn tất quá trình
              đăng ký vé.
            </>
          }
          banner
        />
      </div>
    )
  )
}
