import {
  DatePickerWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { useTimeout } from "@/components/hooks"
import { genderOptions } from "@/utils/constants"
import { convertObjectToArray, notificationModal } from "@/utils/helpers"
import { Form } from "antd"
import moment from "moment"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import "./style.scss"

export const FormTab: React.FC<any> = ({ sessionRegister, resetForm }) => {
  const {
    control,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext()

  const invalidFormAction = () => {
    resetForm()
    notificationModal({
      type: "error",
      className: "c-event-popup",
      content: "Hết thời gian đăng ký vé!",
      maskClosable: false,
    })
  }

  const futureDate = moment(sessionRegister)
  const now = moment()
  const diff = futureDate.diff(now)

  useTimeout(
    () => invalidFormAction(),
    diff === -1 || diff === 0 || Number.isNaN(diff) ? 100000000 : diff,
  )

  return (
    <div className="t-form-tab">
      <Form layout="vertical">
        <FormItemWrap
          label="Họ và tên"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputWrap
                placeholder="Ví dụ: Nguyễn Minh Cảnh"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Ngày sinh"
          required
          validateStatus={errors?.birthday ? "error" : ""}
          help={<>{errors?.birthday && errors?.birthday?.message}</>}
        >
          <Controller
            name="birthday"
            control={control}
            render={({ field }) => (
              <DatePickerWrap
                className="c-birthday"
                placeholder="Ví dụ: 13/05/1989"
                format="DD/MM/YYYY"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Giới tính"
          required
          validateStatus={errors?.gender ? "error" : ""}
          help={<>{errors?.gender && errors?.gender?.message}</>}
        >
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <SelectWrap
                placeholder="Chọn giới tính"
                options={convertObjectToArray(genderOptions)}
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Số điện thoại"
          required
          validateStatus={errors?.phone ? "error" : ""}
          help={<>{errors?.phone && errors?.phone?.message}</>}
        >
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputWrap
                placeholder="Ví dụ: 0396687531"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Email"
          required
          validateStatus={errors?.email ? "error" : ""}
          help={<>{errors?.email && errors?.email?.message}</>}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputWrap
                placeholder="Ví dụ: email@example.com"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Facebook"
          required
          validateStatus={errors?.facebook ? "error" : ""}
          help={<>{errors?.facebook && errors?.facebook?.message}</>}
        >
          <Controller
            name="facebook"
            control={control}
            render={({ field }) => (
              <InputWrap
                placeholder="Ví dụ: https://www.facebook.com/denvaufc"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
