import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EventTicket {
  getTicketByTicketCode = (params: any): any => {
    return http.get(ENDPOINT.EVENT_TICKET.GET_TICKET_BY_TICKET_CODE, {
      params: params,
    })
  }

  createTicketInfo = (body: any): any => {
    return http.post(ENDPOINT.EVENT_TICKET.CREATE_TICKET_INFO, body)
  }

  updateTicketInfo = (body: any): any => {
    return http.post(ENDPOINT.EVENT_TICKET.UPDATE_TICKET_INFO, body)
  }

  confirmPayment = (body: any): any => {
    return http.post(ENDPOINT.EVENT_TICKET.CONFIRM_PAYMNET, body)
  }

  getMyTicket = () => {
    return http.get(ENDPOINT.EVENT_TICKET.GET_MY_TICKETS)
  }
}
export const EventTicketService = new EventTicket()
