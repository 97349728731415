import * as yup from "yup"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = () =>
  yup.object().shape({
    email: yup
      .string()
      .email("Email không hợp lệ")
      .required("Email là bắt buộc"),
    password: yup
      .string()
      .min(8, "Mật khẩu phải có ít nhất 8 ký tự")
      .required("Mật khẩu là bắt buộc"),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null] as any,
        "Mật khẩu xác nhận phải giống với mật khẩu",
      )
      .required("Mật khẩu xác nhận là bắt buộc"),
    full_name: yup.string().required("Vui lòng nhập Họ và tên"),
  })
