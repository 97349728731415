import { Home, Login, MeetingInfo, Register } from "@/pages"
import { MyTicket } from "@/pages/MyTicket"
import { TicketRegister } from "@/pages/TicketRegister"
import { getTitlePage } from "@/utils/helpers"
import { Navigate, Outlet, useLocation } from "react-router-dom"

export interface IRouteWrapProps {
  isAuthenticated: boolean
}

export const APP_ROUTER = {
  AUTH: {
    LOGIN: "/login",
    REGISTER: "/register",
  },
  HOME: "/",
  MY_TICKET: "/my-ticket",
  MEETING_INFO: "/dong-am-meeting",
  TICKET_REGISTER: "/ticket-register",
}

export const publicRoutes = [
  {
    path: APP_ROUTER.AUTH.LOGIN,
    element: <Login title="Đăng nhập" />,
  },
  {
    path: APP_ROUTER.AUTH.REGISTER,
    element: <Register title="Đăng ký" />,
  },
]

export const privateRoutes = [
  {
    path: APP_ROUTER.HOME,
    element: <MyTicket title={getTitlePage("Vé của tôi")} />,
  },
  {
    path: APP_ROUTER.MY_TICKET,
    element: <MyTicket title={getTitlePage("Vé của tôi")} />,
  },
  {
    path: APP_ROUTER.TICKET_REGISTER,
    element: <TicketRegister title={getTitlePage("Đăng ký vé")} />,
  },
]

export const PublicRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation()

  return isAuthenticated ? (
    <Navigate to={APP_ROUTER.MY_TICKET} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export const PrivateRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation()

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTER.AUTH.LOGIN} state={{ from: location }} replace />
  )
}
