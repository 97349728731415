import { ButtonWrap } from "@/components/commons"
import { t } from "@/utils/helpers"
import { Result } from "antd"
import "./style.scss"

export const NotFound = () => {
  return (
    <div className="p-404">
      <Result
        status="404"
        title={t("P_NOTFOUND.TITLE")}
        subTitle={t("P_NOTFOUND.DESCRIPTION")}
        extra={
          <ButtonWrap type="primary">
            {t("P_NOTFOUND.BUTTON_GO_HOME")}
          </ButtonWrap>
        }
      />
    </div>
  )
}
