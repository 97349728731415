import { REGEX } from "@/utils/constants"
import * as yup from "yup"

export const formSchemaFn = () =>
  yup.object().shape({
    name: yup.string().required("Vui lòng nhập Họ và tên!"),
    birthday: yup.string().required("Vui lòng nhập Ngày sinh!"),
    gender: yup.number().required("Vui lòng chọn Giới tính!"),
    email: yup
      .string()
      .required("Vui lòng nhập Email!")
      .matches(REGEX.EMAIL, "Vui lòng nhập đúng định dạng Email!"),
    phone: yup
      .string()
      .required("Vui lòng nhập Số điện thoại!")
      .matches(REGEX.PHONE, "Vui lòng nhập đúng định dạng Số điện thoại!"),
    facebook: yup.string().required("Vui lòng nhập Địa chỉ Facebook!"),
  } as any)
